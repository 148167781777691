.homegrid-li {
    width: 33%;
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
    text-align: center;
}

.homegrid-li p {
    margin: 0;
}

.homegridimg {
    width: 70%;
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
}