.content {
    width: 100%;
}

.paysucqr {
    width: 100%;
    justify-content: center; /* 水平居中 */
    overflow-clip-margin: content-box;
    overflow: clip;
}

.novipqrbg {
    position: absolute;
    width: 100%;
    z-index: -1;
    justify-content: center; /* 水平居中 */
    overflow-clip-margin: content-box;
    overflow: clip;
}

.novipt {
    display: flex;
    align-items: center;
    width: 70%;
    margin: 0 auto;
    padding-top: 2rem;
}

.novipt img {
    width: 4rem;
    height: 4rem;
}

.novipt span {
    font-size: 1.46rem;
    color: #000000;
    margin-left: 0.5rem;
}

.novipp {
    width: 55%;
    text-align: center;
    margin: 0 auto;
    font-size: 1.35rem;
    color: #fff;
}

.novipp span {
    color: #fba002;
}

.novipp p {
    margin: 0;
    color: #34b449;
    font-weight: bold;
}

.payloading {
    width: 100%;
    height: 100%;
    position: absolute;
    align-items: center;
    justify-content: center;
    display: flex;
}