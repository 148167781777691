.topnavheader {
    width: 100%;
    height: 56px;
    display: flex;
    align-items: center;
    border-bottom: 0.1rem solid #e6e6e6
}

.topnavheader p {
    width: 80%;
    text-align: center;
    font-size: 1.375rem;
    font-weight: bold;
}

.topnavimg {
    width: 24px;
    height: 24px;
    margin-left: 14px;
    margin-top: 16px;
    margin-bottom: 16px;
}